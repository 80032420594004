import React from 'react';
import { useState, useEffect } from 'react';
import '../css_components/App.css';
import '../css_components/Homepage.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Homepage from './Homepage';
import WeddingParty from './WeddingParty';
import WeddingDetails from './WeddingDetails';
import Venue from './Venue';
import TravelGuide from './TravelGuide';
import QAndA from './QAndA';
import Honeymoon from './Honeymoon';
import NotFound from './NotFound';
import Modal from 'react-modal';
import background1 from '../images/background1-modal.jpg';
import backgroundMobile from '../images/background1Mobile.jpg'
import { Image } from 'semantic-ui-react';
import Navbar from './Navbar';
import logo from '../images/logo-2.png'
import Food from './Food'
import { BrowserView, MobileView } from 'react-device-detect';
import ScrollToTop from './hooks/ScrollToTop';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 0,
    border: 'none',
    borderColor: 'none',
    width: '100%',
    height: '100%',
    padding: '0px',
  },
};

function App() {
  let subtitle;
  const [key, setKey] = useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(true);

  useEffect(() => {
    if (localStorage.getItem('auth') !== null){
      if (localStorage.getItem('auth') === '310523'){
        setIsOpen(false);
      }
    }
    if (modalIsOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [modalIsOpen]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#000000';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function login() {
    if(key === "310523"){
      setIsOpen(false);
      localStorage.setItem('auth', '310523')
    }
  }

  function handleChange(event) {
    if (event.key === 'Enter'){
      login()
    }
    setKey(event.target.value);
  }

  return (
    <div className='root'>
      <BrowserView>
        <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Login Modal"
              preventScroll={true}
            >
          <div className='head-text'>
            <Image 
              className='mainImage'
              style={{
                width: '100vw',
                height: '100vh',
                aspectRatio: 6720/3250
              }}
              src={background1} size='medium' centered 
            />
          </div>
          <div className='logo-on-image'>
            <Image 
              style={{
                width: '30vw',
                height: undefined,
                aspectRatio: 1563/953
              }}
              src={logo} size='medium' centered 
            />
          </div>
          <div className='text-on-image-2'>
            <h2 className='header1Login' ref={(_subtitle) => (subtitle = _subtitle)}>Please enter access code</h2>
            <input className='password' 
              onKeyPress={handleChange}
              onChange={handleChange} />
          </div>
          <div className='text-on-image-3'>
            <button className='enterButton' onClick={() => login()}>Enter</button>
          </div>
        </Modal>
      </BrowserView>
      <MobileView>
        <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Login Modal"
              preventScroll={true}
            >
          <div className='head-text'>
            <Image 
              className='mainImage'
              style={{
                width: '100vw',
                height: '100vh',
                aspectRatio: 1750/3329
              }}
              src={backgroundMobile} size='medium' centered 
            />
          </div>
          <div className='logo-on-image' style={{marginTop: '45%'}}>
            <Image 
              style={{
                width: '60vw',
                height: undefined,
                aspectRatio: 1563/953
              }}
              src={logo} size='medium' centered 
            />
          </div>
          <div className='text-on-image-2' style={{marginTop: '69%'}}>
            <h2 className='header1Login' ref={(_subtitle) => (subtitle = _subtitle)}>Please enter access code</h2>
            <input className='password' style={{height: '12vw', width: '50vw', fontSize: '6vw'}}
              onKeyPress={handleChange}
              onChange={handleChange} />
          </div>
          <div className='text-on-image-3' style={{marginTop: '90%'}}>
            <button className='enterButton' style={{height: '12vw', width: '50vw', marginTop: '20%', fontSize: '6vw'}} onClick={() => login()}>Enter</button>
          </div>
        </Modal>
      </MobileView>
      <BrowserRouter>
      <ScrollToTop />
      <Navbar shouldRender={modalIsOpen}/>
       <Routes>
        <Route exact path="/" element={new Homepage()}/>
        <Route exact path="/details" element={<WeddingDetails/>}/>
        <Route exact path="/food" element={<Food/>}/>
        <Route exact path="/party" element={<WeddingParty/>}/>
        <Route exact path="/venue" element={<Venue/>}/>
        <Route exact path="/travel" element={<TravelGuide/>}/>
        <Route exact path="/faq" element={<QAndA/>}/>
        <Route exact path="/honeymoon" element={<Honeymoon/>}/>
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;