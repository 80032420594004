import React from 'react';
import '../css_components/App.css'
import '../css_components/Common.css'
import '../css_components/Homepage.css'
import Footer from './Footer';
import {Image, Grid } from 'semantic-ui-react'
import background1 from '../images/grilled-steak-fillet-with-fresh-vegetable-salad-generated-by-ai.jpg';
import { BrowserView, TabletView, CustomView, isMobile, isTablet } from 'react-device-detect';
import backgroundMobile from '../images/foodMobile.jpg';

function Homepage() {
    return (
        <div>
          <BrowserView>
            <div>
              <div className='head-text'>
                <Image 
                  style={{
                    width: '100vw',
                    height: undefined,
                    aspectRatio: 6720/3329
                  }}
                  src={background1} size='medium' centered 
                />
              </div>
              <div className='text-on-image'>
                <h3 className='header1'>Food & Drink</h3>
              </div>
            </div>
          </BrowserView>
          <CustomView condition={isMobile && !isTablet}>
            <div>
              <div className='head-text'>
                <Image 
                  style={{
                    width: '100vw',
                    height: '100vh',
                    aspectRatio: 1/2
                  }}
                  src={backgroundMobile} size='medium' centered 
                />
              </div>
              <div className='text-on-image' style={{top: '87vw'}}>
                <h3 className='header1'>Food</h3>
                <h3 className='header1' style={{marginTop: '-5vw'}}>&</h3>
                <h3 className='header1' style={{marginTop: '-5vw'}}>Drink</h3>
              </div>
            </div>
          </CustomView>
          <TabletView>
            <div>
                <div className='head-text'>
                  <Image 
                    style={{
                      width: '100vw',
                      height: '100vh',
                      aspectRatio: 1/2
                    }}
                    src={backgroundMobile} size='medium' centered 
                  />
                </div>
                <div className='text-on-image' style={{top: '70vw'}}>
                  <h3 className='header1'>Food</h3>
                  <h3 className='header1' style={{marginTop: '-3vw'}}>&</h3>
                  <h3 className='header1' style={{marginTop: '-3vw'}}>Drink</h3>
                </div>
              </div>
          </TabletView>

          <BrowserView>
            <Grid class='qanda' divided='vertically'>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText'>Canapés</h1>
                <p className='foodTextBody'>Lamb kebabs, minted yoghurt</p>
                <p className='foodTextBody'>Goat’s cheese and shallot tart (V)</p>
                <p className='foodTextBody'>Potato and onion bhaji, mint and cucumber (VE)</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText'>Standard Menu</h1>
                <h1 className='foodTextSub'>Starter</h1>
                <p className='foodTextBody'>Scallops, wild mushroom tortellini, buttered spinach, shallot cream sauce</p>
                <h1 className='foodTextSub'>Palate Cleanser</h1>
                <p className='foodTextBody'>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub'>Main</h1>
                <p className='foodTextBody'>Sirloin steak, potato and stilton gratin, grilled field mushroom and peppercorn sauce</p>
                <h1 className='foodTextSub'>Dessert</h1>
                <p className='foodTextBody'>Baked pear tart, almond cream, quince jelly and salted caramel ice cream</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText'>Vegetarian Menu</h1>
                <h1 className='foodTextSub'>Starter</h1>
                <p className='foodTextBody'>Roast Jerusalem artichokes, new potatoes, pickled leek and hazelnut vinaigrette</p>
                <h1 className='foodTextSub'>Palate Cleanser</h1>
                <p className='foodTextBody'>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub'>Main</h1>
                <p className='foodTextBody'>Roast white onions, Savoy cabbage, heritage carrots and sage gnocchi</p>
                <h1 className='foodTextSub'>Dessert</h1>
                <p className='foodTextBody'>Baked pear tart, almond cream, quince jelly and salted caramel ice cream</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText'>Vegan Menu</h1>
                <h1 className='foodTextSub'>Starter</h1>
                <p className='foodTextBody'>Roast Jerusalem artichokes, new potatoes, pickled leek and hazelnut vinaigrette</p>
                <h1 className='foodTextSub'>Palate Cleanser</h1>
                <p className='foodTextBody'>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub'>Main</h1>
                <p className='foodTextBody'>Roast white onions, Savoy cabbage, heritage carrots and sage gnocchi</p>
                <h1 className='foodTextSub'>Dessert</h1>
                <p className='foodTextBody'>Apple and blackberry compote, hazelnut crumb, vanilla and cinnamon-spiced coconut cream</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid class='qanda' divided='horizontally' style={{marginTop: '-5%', width: '90%', marginLeft: '5%', marginRight: '5%'}} >
              <Grid.Row style={{textAlign: 'center'}}>
                <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodText'>Drinks</h1>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{textAlign: 'center'}}>
                <Grid.Column class='whiteColumn' style={{width: '25%', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{marginLeft: '10%', marginRight: '10%'}}>Reception Drink</h1>
                  <p className='foodTextBody1' style={{width: '80%', marginLeft: '10%', marginRight: '10%'}}>Spiced Apple Martini</p>
                </Grid.Column>
                <Grid.Column class='whiteColumn' style={{width: '25%', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{marginLeft: '10%', marginRight: '10%'}}>Soft Drink</h1>
                  <p className='foodTextBody1' style={{width: '80%', marginLeft: '10%', marginRight: '10%'}}>Lemongrass Sparkler</p>
                </Grid.Column>
                <Grid.Column class='whiteColumn' style={{width: '25%', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{marginLeft: '10%', marginRight: '10%'}}>Toast</h1>
                  <p className='foodTextBody1' style={{width: '80%', marginLeft: '10%', marginRight: '10%'}}>Louis Dornier et Fils Brut Champagne</p>
                </Grid.Column>
                <Grid.Column class='whiteColumn' style={{width: '25%', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{marginLeft: '10%', marginRight: '10%'}}>Bar</h1>
                  <p className='foodTextBody1' style={{width: '80%', marginLeft: '10%', marginRight: '10%'}}>Available until 23:15 </p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{textAlign: 'center'}}>
                <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{marginLeft: '10%', marginRight: '10%'}}>Dinner Drinks</h1>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{textAlign: 'center'}}>
                <Grid.Column class='whiteColumn' style={{width: '50%', textAlign: 'center', backgroundColor: 'white'}}>
                  <p className='foodTextBody1' style={{width: '100%', marginLeft: '10%', marginRight: '10%'}}>Brazos Malbec</p>
                </Grid.Column>
                <Grid.Column class='whiteColumn' style={{width: '50%', textAlign: 'center', backgroundColor: 'white'}}>
                  <p className='foodTextBody1' style={{width: '80%', marginLeft: '10%', marginRight: '10%'}}>Esporão Bico Amarelo, Vinho Verde</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            </Grid.Row>
            </Grid>
          </BrowserView>

          <CustomView condition={isMobile && !isTablet}>
            <Grid class='qanda' divided='vertically'>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText' style={{fontSize: '40px'}}>Canapés</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Lamb kebabs, minted yoghurt</p>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Goat’s cheese and shallot tart (V)</p>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Potato and onion bhaji, mint and cucumber (VE)</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText' style={{fontSize: '40px'}}>Standard Menu</h1>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Starter</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Scallops, wild mushroom tortellini, buttered spinach, shallot cream sauce</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Palate Cleanser</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Main</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Sirloin steak, potato and stilton gratin, grilled field mushroom and peppercorn sauce</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Dessert</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Baked pear tart, almond cream, quince jelly and salted caramel ice cream</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText' style={{fontSize: '40px'}}>Vegetarian Menu</h1>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Starter</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Roast Jerusalem artichokes, new potatoes, pickled leek and hazelnut vinaigrette</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Palate Cleanser</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Main</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Roast white onions, Savoy cabbage, heritage carrots and sage gnocchi</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Dessert</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Baked pear tart, almond cream, quince jelly and salted caramel ice cream</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText' style={{fontSize: '40px'}}>Vegan Menu</h1>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Starter</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Roast Jerusalem artichokes, new potatoes, pickled leek and hazelnut vinaigrette</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Palate Cleanser</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Main</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Roast white onions, Savoy cabbage, heritage carrots and sage gnocchi</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Dessert</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Apple and blackberry compote, hazelnut crumb, vanilla and cinnamon-spiced coconut cream</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid class='qanda' divided='horizontally' style={{marginTop: '-5%', width: '90%', marginLeft: '5%', marginRight: '5%'}} >
              <Grid.Row style={{textAlign: 'center'}}>
                <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodText' style={{fontSize: '40px'}}>Drinks</h1>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row class='whiteColumn' style={{justifyContent:'center', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{fontSize: '28px',marginLeft: '10%', marginRight: '10%'}}>Reception Drink</h1>
                  <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%'}}>Spiced Apple Martini</p>
                </Grid.Row>
                <Grid.Row class='whiteColumn' style={{justifyContent:'center', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{fontSize: '28px', marginLeft: '10%', marginRight: '10%'}}>Soft Drink</h1>
                  <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%'}}>Lemongrass Sparkler</p>
                </Grid.Row>
                <Grid.Row class='whiteColumn' style={{justifyContent: 'center', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{fontSize: '28px', marginLeft: '10%', marginRight: '10%'}}>Toast</h1>
                  <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%'}}>Louis Dornier et Fils Brut Champagne</p>
                </Grid.Row>
                <Grid.Row class='whiteColumn' style={{textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{fontSize: '28px', marginLeft: '10%', marginRight: '10%'}}>Dinner Drinks</h1>
                  <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%'}}>Brazos Malbec</p>
                  <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%'}}>Esporão Bico Amarelo Vinho Verde</p>
                </Grid.Row>
                <Grid.Row class='whiteColumn' style={{justifyContent: 'center', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodTextSub1' style={{fontSize: '28px', marginLeft: '10%', marginRight: '10%'}}>Bar</h1>
                  <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%'}}>Available until 23:15 </p>
                </Grid.Row>
            </Grid>
            </Grid.Row>
            </Grid>
          </CustomView>

          <TabletView>
            <Grid class='qanda' divided='vertically'>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText' style={{fontSize: '40px'}}>Canapés</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Lamb kebabs, minted yoghurt</p>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Goat’s cheese and shallot tart (V)</p>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Potato and onion bhaji, mint and cucumber (VE)</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText' style={{fontSize: '40px'}}>Standard Menu</h1>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Starter</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Scallops, wild mushroom tortellini, buttered spinach, shallot cream sauce</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Palate Cleanser</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Main</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Sirloin steak, potato and stilton gratin, grilled field mushroom and peppercorn sauce</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Dessert</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Baked pear tart, almond cream, quince jelly and salted caramel ice cream</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText' style={{fontSize: '40px'}}>Vegetarian Menu</h1>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Starter</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Roast Jerusalem artichokes, new potatoes, pickled leek and hazelnut vinaigrette</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Palate Cleanser</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Main</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Wild mushroom tart, crisp kale, roasted chestnuts and truffle cream sauce</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Palate Cleanser</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Baked pear tart, almond cream, quince jelly and salted caramel ice cream</p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center'}}>
                <h1 className='foodText' style={{fontSize: '40px'}}>Vegan Menu</h1>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Starter</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Roast Jerusalem artichokes, new potatoes, pickled leek and hazelnut vinaigrette</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Palate Cleanser</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Champagne sorbet, Champagne and strawberries</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Main</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Roast white onions, Savoy cabbage, heritage carrots and sage gnocchi</p>
                <h1 className='foodTextSub' style={{fontSize: '28px'}}>Dessert</h1>
                <p className='foodTextBody' style={{fontSize: '34px'}}>Apple and blackberry compote, hazelnut crumb, vanilla and cinnamon-spiced coconut cream</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            <Grid class='qanda' divided='horizontally' style={{marginTop: '-5%', width: '90%', marginLeft: '5%', marginRight: '5%'}} >
              <Grid.Row style={{textAlign: 'center'}}>
                <Grid.Column class='whiteColumn' style={{width: '100%', textAlign: 'center', backgroundColor: 'white'}}>
                  <h1 className='foodText' style={{fontSize: '40px'}}>Drinks</h1>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row class='whiteColumn' style={{justifyContent:'center', textAlign: 'center', backgroundColor: 'white'}}>
                <h1 className='foodTextSub1' style={{fontSize: '28px',marginLeft: '10%', marginRight: '10%', width: '100%'}}>Reception Drink</h1>
                <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Spiced Apple Martini</p>
              </Grid.Row>
              <Grid.Row class='whiteColumn' style={{justifyContent:'center', textAlign: 'center', backgroundColor: 'white'}}>
                <h1 className='foodTextSub1' style={{fontSize: '28px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Soft Drink</h1>
                <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Lemongrass Sparkler</p>
              </Grid.Row>
              <Grid.Row class='whiteColumn' style={{justifyContent: 'center', textAlign: 'center', backgroundColor: 'white'}}>
                <h1 className='foodTextSub1' style={{fontSize: '28px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Toast</h1>
                <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Louis Dornier et Fils Brut Champagne</p>
              </Grid.Row>
              <Grid.Row class='whiteColumn' style={{textAlign: 'center', backgroundColor: 'white'}}>
                <h1 className='foodTextSub1' style={{fontSize: '28px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Dinner Drinks</h1>
                <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Brazos Malbec</p>
                <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Esporão Bico Amarelo Vinho Verde</p>
              </Grid.Row>
              <Grid.Row class='whiteColumn' style={{justifyContent: 'center', textAlign: 'center', backgroundColor: 'white'}}>
                <h1 className='foodTextSub1' style={{fontSize: '28px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Bar</h1>
                <p className='foodTextBody1' style={{fontSize: '34px', marginLeft: '10%', marginRight: '10%', width: '100%'}}>Available until 23:15 </p>
              </Grid.Row>
            </Grid>
            </Grid.Row>
            </Grid>
          </TabletView>

          <Footer/>
        </div>
      );
  }
  
export default Homepage;