import React from 'react';
import '../css_components/App.css';
import "../css_components/QandA.css";
import { Grid, Image } from 'semantic-ui-react'
import { Link, NavLink} from 'react-router-dom';
import background3 from '../images/background3.jpg';
import Footer from './Footer.js';
import { BrowserView, CustomView, TabletView, isMobile, isTablet} from 'react-device-detect';
import backgroundMobile from '../images/qandaMobile.jpg';

function QAndA() {
    return (
      <div>
        <BrowserView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: undefined,
                  aspectRatio: 6720/3329
                }}
                src={background3} size='medium' centered 
              />
            </div>
            <div className='text-on-image'>
              <h3 className='header1'>Q&A</h3>
            </div>
          </div>
        </BrowserView>
        <CustomView condition={isMobile && !isTablet}>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top: '87vw'}}>
              <h3 className='header1'>Q&A</h3>
            </div>
          </div>
        </CustomView>
        <TabletView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top: '70vw'}}>
              <h3 className='header1'>Q&A</h3>
            </div>
          </div>
        </TabletView>
        

      <div>
        <Grid class='qanda' divided='vertically'>
          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>Can I bring my children?</h1>
              <p className='qandaTextBody'>While we love your little ones, our wedding is going to be an adults-only event to ensure that everyone will be able to relax and enjoy the evening.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>Can I bring a guest?</h1>
              <p className='qandaTextBody'>If your invitation says “and Guest”, then yes! Otherwise, we would love to keep our wedding as an intimate event with just our close friends and family.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>What’s the dress code?</h1>
              <p className='qandaTextBody'>We want everyone to feel comfortable and confident in what they’re wearing, smart/casual or cocktail attire is fine. If it helps, we have gone with an autumnal colour scheme.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>Is it outdoors?</h1>
              <p className='qandaTextBody'>Our ceremony will be held outside, but the reception and dinner will be under cover. In the event of bad weather, the ceremony will be held inside the barn.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>Can I take pictures during the ceremony?</h1>
              <p className='qandaTextBody'>We will be having an &quot;unplugged&quot; ceremony, this just means we&apos;re asking that all phones and devices are put away and are on silent during the ceremony. This will then allow our photographer to capure the moment with everyone present and ensures that their shots are free of mobile phones. After the ceremony is over, feel free to take as many photos as you like!</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>How do I get there?</h1>
              <p className='qandaTextBody'>Details can be found <Link class='extLink' to='https://goo.gl/maps/X5zjsarAVsVnuKUA9'><p className='venuePara'>Here.</p></Link></p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>What time should I arrive?</h1>
              <p className='qandaTextBody'>Please arrive at the venue for 14:00, this allows time for you to grab a drink, go to the bathroom and for everyone to be seated before the ceremony begins.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>Where can I park?</h1>
              <p className='qandaTextBody'>There is ample parking onsite for all our guests at Rivervale Barn. Guests can leave their cars overnight but must be collected between 08:00 and 10:00 the next day.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>Where can I stay?</h1>
              <p className='qandaTextBody'>We&apos;ve made some suggestions of nearby hotels on our travel and accomodation page <NavLink className='extLink' exact to='/travel'><p className='venuePara'>Here.</p></NavLink></p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>When is the RSVP deadline?</h1>
              <p className='qandaTextBody'>For us to be able to ensure that everyone is catered for on the day, please RSVP by Tuesday 30th July 2024.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>Will there be food and drinks served at the reception?</h1>
              <p className='qandaTextBody'>Yes, we have food arranged to be served during the reception, this includes vegetarian and vegan options as well. Although, please do let us know in your RSVP if you have any dietary requirements or allergies.</p>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column style={{width: '100%'}}>
              <h1 className='qandaText'>Do you have a wedding registry?</h1>
              <p className='qandaTextBody'>The greatest gift we could ask for is for all of our friends and family to be attending our big day. However, if you wish to get a gift, we have a link on our website to donate towards our honeymoon savings pot <NavLink className='extLink' exact to='/honeymoon'><p className='venuePara'>Here.</p></NavLink></p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      <Footer/>
    </div>
      );
  }
  
export default QAndA;