import React from 'react';
import '../css_components/App.css';
import "../css_components/QandA.css";
import "../css_components/WeddingDetails.css"
import { Image } from 'semantic-ui-react'
import background3 from '../images/beautiful-bouquet-wild-flowers-hands-bride.jpg';
import Footer from './Footer.js';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import champagne from '../images/champagne-2.png';
import rings from '../images/rings-3.png';
import cake from '../images/cake-4.png';
import photos from '../images/photo-2.png';
import dinner from '../images/dinner-2.png';
import party from '../images/party-1.png';
import arch from '../images/ceremony-1.png';
import { BrowserView, MobileView, CustomView, TabletView, isMobile, isTablet } from 'react-device-detect';
import backgroundMobile from '../images/orderOfTheDayMobile.jpg';

function WeddingDetails() {
    return (
      <div>
        <BrowserView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: undefined,
                  aspectRatio: 5040/2695
                }}
                src={background3} size='medium' centered 
              />
            </div>
            <div className='text-on-image'>
              <h3 className='header1'>The Order of The Day</h3>
            </div>
          </div>
        </BrowserView>
        <CustomView condition={isMobile && !isTablet}>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top:'87vw', left: '10%', width: '80%'}}>
              <h3 className='header1'>The Order of The Day</h3>
            </div>
          </div>
        </CustomView>
        <TabletView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top:'70vw', left: '10%', width: '80%'}}>
              <h3 className='header1'>The Order of The Day</h3>
            </div>
          </div>
        </TabletView>
        <BrowserView>
          <div className='timeline'>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={arch} alt="arch" style={{"width":"16em", "height":"16em"}} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div style={{"width":"30%", "marginLeft":"-8%"}}>
                    <p className='timelineBody'>14:00</p>
                  </div>
                  <div style={{"width":"40%"}}>
                    <p className='timelineBody'>Arrive</p>
                    <p className='timelineBody2'>The Reception Area</p>
                  </div>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={rings} alt="rings" style={{"width":"16em", "height":"16em"}} />
                  </TimelineDot>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div style={{"width":"30%", "marginLeft":"-8%"}}>
                    <p className='timelineBody'>15:00</p>
                  </div>
                  <div style={{"width":"40%"}}>
                    <p className='timelineBody'>We say &quot;I Do&quot;</p>
                    <p className='timelineBody2'>The Outdoor Spinney</p>
                  </div>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={photos} alt="photos" style={{"width":"16em", "height":"16em"}} />
                  </TimelineDot>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div style={{"width":"30%", "marginLeft":"-8%"}}>
                    <p className='timelineBody'>15:30</p>
                  </div>
                  <div style={{"width":"40%"}}>
                    <p className='timelineBody'>Photos, Cocktails & Canapés</p>
                    <p className='timelineBody2'>The Courtyard & Gardens</p>
                  </div>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={dinner} alt="dinner" style={{"width":"16em", "height":"16em"}} />
                  </TimelineDot>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div style={{"width":"30%", "marginLeft":"-8%"}}>
                    <p className='timelineBody'>18:00</p>
                  </div>
                  <div style={{"width":"40%"}}>
                    <p className='timelineBody'>Dinner</p>
                    <p className='timelineBody2'>The Dining Hall</p>
                  </div>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={champagne} alt="chanmpagne" style={{"width":"16em", "height":"16em"}} />
                  </TimelineDot>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div style={{"width":"30%", "marginLeft":"-8%"}}>
                    <p className='timelineBody'>20:30</p>
                  </div>
                  <div style={{"width":"40%"}}>
                    <p className='timelineBody'>Toasts & Speeches</p>
                    <p className='timelineBody2'>The Dining Hall</p>
                  </div>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={cake} alt="cake" style={{"width":"16em", "height":"16em"}} />
                  </TimelineDot>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div style={{"width":"30%", "marginLeft":"-8%"}}>
                    <p className='timelineBody'>21:15</p>
                  </div>
                  <div style={{"width":"40%"}}>
                    <p className='timelineBody'>Cake Cutting</p>
                    <p className='timelineBody2'>The Reception Area</p>
                  </div>
                </TimelineContent>
              </TimelineItem>
              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={party} alt="party" style={{"width":"16em", "height":"16em"}} />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                  <div style={{"width":"30%", "marginLeft":"-8%"}}>
                    <p className='timelineBody'>21:30</p>
                  </div>
                  <div style={{"width":"40%"}}>
                    <p className='timelineBody'>Dancing</p>
                    <p className='timelineBody2'>The Reception Area</p>
                  </div>
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          </div>
        </BrowserView>
        <MobileView>
          <div className='timeline' style={{marginLeft: '0'}}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              <TimelineItem style={{justifyContent: 'center'}}>
                <TimelineSeparator>
                  <TimelineDot style={{marginLeft: '10%'}}>
                    <img src={arch} alt="arch" style={{"width":"8em", "height":"8em"}} />
                  </TimelineDot>
                  <TimelineContent style={{display: 'block'}}>
                    <div style={{"width":"100%", justifyContent: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>14:00</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>Arrive</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody2' style={{fontSize: '26px'}}>The Reception Area</p>
                    </div>
                  </TimelineContent>
                  <TimelineConnector />
                </TimelineSeparator>
              </TimelineItem>
              <TimelineItem style={{justifyContent: 'center'}}>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={rings} alt="rings" style={{"width":"8em", "height":"8em"}} />
                  </TimelineDot>
                  <TimelineContent style={{display: 'block'}}>
                    <div style={{"width":"100%", justifyContent: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>15:00</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>We say &quot;I Do&quot;</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody2' style={{fontSize: '26px'}}>The Outdoor Spinney</p>
                    </div>
                  </TimelineContent>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
              </TimelineItem>
              <TimelineItem style={{justifyContent: 'center'}}>
                <TimelineSeparator>
                  <TimelineDot style={{marginLeft: '10%'}}>
                    <img src={photos} alt="photos" style={{"width":"8em", "height":"8em"}} />
                  </TimelineDot>
                  <TimelineContent style={{display: 'block'}}>
                    <div style={{"width":"100%", justifyContent: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>15:30</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>Photos, Cocktails & Canapés</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                      <p className='timelineBody2' style={{fontSize: '26px'}}>The Courtyard & Gardens</p>
                    </div>
                  </TimelineContent>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
              </TimelineItem>
              <TimelineItem style={{justifyContent: 'center'}}>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={dinner} alt="dinner" style={{"width":"8em", "height":"8em"}} />
                  </TimelineDot>
                  <TimelineContent style={{display: 'block'}}>
                    <div style={{"width":"100%", justifyContent: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>18:00</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>Dinner</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody2' style={{fontSize: '26px'}}>The Dining Hall</p>
                    </div>
                  </TimelineContent>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
              </TimelineItem>
              <TimelineItem style={{justifyContent: 'center'}}>
                <TimelineSeparator>
                  <TimelineDot>
                    <img src={champagne} alt="chanmpagne" style={{"width":"8em", "height":"8em"}} />
                  </TimelineDot>
                  <TimelineContent style={{display: 'block'}}>
                    <div style={{"width":"100%", justifyContent: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>20:30</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>Toasts & Speeches</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody2' style={{fontSize: '26px'}}>The Dining Hall</p>
                    </div>
                  </TimelineContent>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
              </TimelineItem>
              <TimelineItem style={{justifyContent: 'center'}}>
                <TimelineSeparator>
                  <TimelineDot style={{marginLeft: '10%'}}>
                    <img src={cake} alt="cake" style={{"width":"8em", "height":"8em"}} />
                  </TimelineDot>
                  <TimelineContent style={{display: 'block'}}>
                    <div style={{"width":"100%", justifyContent: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>21:15</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>Cake Cutting</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody2' style={{fontSize: '26px'}}>The Reception Area</p>
                    </div>
                  </TimelineContent>
                  <TimelineConnector />
                  <TimelineConnector />
                </TimelineSeparator>
              </TimelineItem>
              <TimelineItem style={{justifyContent: 'center'}}>
                <TimelineSeparator>
                  <TimelineDot style={{marginLeft: '10%'}}>
                    <img src={party} alt="party" style={{"width":"8em", "height":"8m"}} />
                  </TimelineDot>
                  <TimelineContent style={{display: 'block'}}>
                    <div style={{"width":"100%", justifyContent: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>21:30</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody' style={{fontSize: '23px'}}>Dancing</p>
                    </div>
                    <div style={{"width":"100%", textAlign: 'center', display: 'flex'}}>
                      <p className='timelineBody2' style={{fontSize: '26px'}}>The Reception Area</p>
                    </div>
                  </TimelineContent>
                </TimelineSeparator>
              </TimelineItem>
            </Timeline>
          </div>
        </MobileView>
      
      <Footer/>
    </div>
      );
  }
  
export default WeddingDetails;