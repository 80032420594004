import React from 'react';
import '../css_components/Navbar.css';
import '../css_components/Common.css'
import {
    Container,
    Menu,
    Image,
  } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';
import logo from '../images/logo-2.png'
import { slide as MobileMenu } from 'react-burger-menu'
import { BrowserView, MobileView } from 'react-device-detect';
import logo2 from '../images/logo.png';
import PropTypes from 'prop-types';

const Content = (props) => {
  if(props.shouldRender !== true) {
    return (
      <div>
        <BrowserView>
          <Menu fixed='top' inverted className='mainMenu'>
            <div>
              <Image 
                style={{
                  maxHeight: '100%',
                  maxWidth: '40%',
                  marginTop: '1%',
                }}
                src={logo} size='medium' centered 
              />
            </div>

            <Container className='navBar'>
              <NavLink exact to='/'><Menu.Item class='navFont' as='a'>Home</Menu.Item></NavLink>
              <NavLink exact to='/details'><Menu.Item class='navFont' as='a'>The Big Day</Menu.Item></NavLink>
              <NavLink exact to='/food'><Menu.Item class='navFont' as='a'>Food & Drink</Menu.Item></NavLink>
              <NavLink exact to='/venue'><Menu.Item class='navFont' as='a'>The Venue</Menu.Item></NavLink>
              <NavLink exact to='/travel'><Menu.Item class='navFont' as='a'>Travel & Accomodation</Menu.Item></NavLink>
              <NavLink exact to='/party'><Menu.Item class='navFont' as='a'>Meet The Wedding Party</Menu.Item></NavLink>
              <NavLink exact to='/faq'><Menu.Item class='navFont' as='a'>Q & A</Menu.Item></NavLink>
              <NavLink exact to='/honeymoon'><Menu.Item class='navFont' as='a'>Honeymoon Pot</Menu.Item></NavLink>
            </Container>
          </Menu>
        </BrowserView>
        <MobileView>
          <MobileMenu>
            <Image centered size='mini' src={logo2} style={{width: '30vw', height: '30vw', marginBottom:'10vw'}}/>
            <a id="home" className="menu-item" href="/" style={{color:'white'}}><Menu.Item class='navFont' as='a' style={{color:'white', marginBottom:'5vw'}}>Home</Menu.Item></a>
            <a id="weddingDetails" className="menu-item" href="/details" style={{color:'white'}}><Menu.Item class='navFont' as='a' style={{color:'white', marginBottom:'5vw'}}>The Big Day</Menu.Item></a>
            <a id="food" className="menu-item" href="/food" style={{color:'white'}}><Menu.Item class='navFont' as='a' style={{color:'white', marginBottom:'5vw'}}>Food & Drink</Menu.Item></a>
            <a id="venue" className="menu-item" href="/venue" style={{color:'white'}}><Menu.Item class='navFont' as='a' style={{color:'white', marginBottom:'5vw'}}>The Venue</Menu.Item></a>
            <a id="travel" className="menu-item" href="/travel" style={{color:'white'}}><Menu.Item class='navFont' as='a' style={{color:'white', marginBottom:'5vw'}}>Travel & Accomodation</Menu.Item></a>
            <a id="weddingParty" className="menu-item" href="/party" style={{color:'white'}}><Menu.Item class='navFont' as='a' style={{color:'white', marginBottom:'5vw'}}>Meet The Wedding Party</Menu.Item></a>
            <a id="qanda" className="menu-item" href="/faq" style={{color:'white'}}><Menu.Item class='navFont' as='a' style={{color:'white', marginBottom:'5vw'}}>Q & A</Menu.Item></a>
            <a id="honeymoon" className="menu-item" href="/honeymoon" style={{color:'white'}}><Menu.Item class='navFont' as='a' style={{color:'white', marginBottom:'5vw'}}>Honeymoon Pot</Menu.Item></a>
          </MobileMenu>
        </MobileView>
      </div>
    );
  }
}

Content.propTypes = {
  shouldRender: PropTypes.bool.isRequired
}

function Navbar(props) {
    return (
      <Content shouldRender={props.shouldRender}/>
    );
  }

Navbar.propTypes = {
  shouldRender: PropTypes.bool.isRequired
}
  
export default Navbar;