import React from 'react';
import '../css_components/App.css';
import "../css_components/QandA.css";
import Nic from '../images/IMG_1619.jpg'
import Gregor from '../images/C705FC65-8C81-4533-87E3-1E2894989EEF.jpg'
import Tue from '../images/IMG_1622.jpg'
import Tom from '../images/IMG_1626.jpg'
import { Grid, Image} from 'semantic-ui-react'
import background3 from '../images/wedding-bridal-accessories-pink-purple-colors-ceremonial-champagne-glasses-wedding-bouquets-bride-bridesmaids.jpg';
import Footer from './Footer.js';
import { BrowserView, MobileView, CustomView, TabletView, isMobile, isTablet } from 'react-device-detect';
import backgroundMobile from '../images/weddingPartyMobile.jpg';

function WeddingParty() {
    return (
      <div>
        <BrowserView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: undefined,
                  aspectRatio: 6555/3392
                }}
                src={background3} size='medium' centered 
              />
            </div>
            <div className='text-on-image'>
              <h3 className='header1'>Meet The Wedding Party</h3>
            </div>
          </div>
        </BrowserView>
        <CustomView condition={isMobile && !isTablet}>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top: '87vw'}}>
              <h3 className='header1'>Meet The Wedding Party</h3>
            </div>
          </div>
        </CustomView>
        <TabletView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top: '70vw'}}>
              <h3 className='header1'>Meet The Wedding Party</h3>
            </div>
          </div>
        </TabletView>

        <div>
          <BrowserView>
            <Grid class='qanda' divided='vertically'>
              <Grid.Row>
                <Grid.Column>
                  <Image 
                    className='mugshots'
                    style = {{
                      width: '90%'
                    }}
                    src={Nic} size='medium'
                  />
                </Grid.Column>
                <Grid.Column style={{width: '67%'}}>
                  <h1 className='qandaText'>Nicole - Maid of Honour</h1>
                  <p className='qandaTextBody'>Nic and Billie worked together over the years in restaurants around Coventry, they quickly became each other&apos;s source of laughter, shoulder to cry on and most importantly, best friend. Nic is like a sister to Bill and they really are just two peas in a pod, despite having completely polar opposite asthetics (pastel pink doesn&apos;t make much of an appearance in Billie&apos;s wardrobe). Undeterred by the looming possibility of Billie stealing Bubble & Squeak (Nic&apos;s two guinea pigs), they have remained firm friends and Billie wouldn&apos;t have been able to do the day without her here.</p>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Image 
                    className='mugshots'
                    style = {{
                      width: '90%'
                    }}
                    src={Gregor} size='medium'
                  />
                </Grid.Column>
                <Grid.Column style={{width: '67%'}}>
                  <h1 className='qandaText'>Greg - Best Man</h1>
                  <p className='qandaTextBody'>Greg and Henry met at university in 2017, they shared a flat and many drunken nights together out in Leicester. Greg and Henry have a love for fast cars, especially Henry&apos;s most prized possession, his Toyota Supra (AKA. the other woman in his life). Keep an ear out for his best man speech, which will no doubt be filled with embarrassing tales, lots of bromance and some alcohol-induced “I love you&apos;s”!</p>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Image 
                    className='mugshots'
                    style = {{
                      width: '90%'
                    }}
                    src={Tom} size='medium'
                  />
                </Grid.Column>
                <Grid.Column style={{width: '67%'}}>
                  <h1 className='qandaText'>Tom - Groomsman</h1>
                  <p className='qandaTextBody'>Tom is Henry&apos;s childhood friend from Ross on Wye, and witness to many of the earlier episodes of his poor decision making. It is highly likely that the patience that allows Tom to be a secondary school teacher and his continued friendship with Henry may have something in common (but he hasn&apos;t given him detention yet!). A reliable source of history facts and an occasional appearence of the second best moustache around, this should hopefully be an occasion that tests his saintly patience less than occasions with Henry have in the past!</p>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Image 
                    className='mugshots'
                    style = {{
                      width: '90%'
                    }}
                    src={Tue} size='medium'
                  />
                </Grid.Column>
                <Grid.Column style={{width: '67%'}}>
                  <h1 className='qandaText'>Tue - Mother of the Bride</h1>
                  <p className='qandaTextBody'>Tue is Billie&apos;s long suffering mother, who after 22 years is understandably looking forward to handing the reins over to Henry. As a food-motivated professional gardener, if you need to find her throughout the evening, she will almost certainly either be in the gardens inspecting the flowers or devouring canapés. She&apos;s done a wonderful job babysitting her four-legged, furry grandson Frank whenever we needed to have a wedding planning day and we can&apos;t thank her enough for being so helpful throughout all of this. Ever since Billie was a little girl, she&apos;d been talking to her mum about what she imagined her wedding day would eventually look like, and now she can&apos;t wait to have her by her side on the big day.</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </BrowserView>
          <MobileView>
            <Grid class='qanda' divided='vertically'>
              <Grid.Row>
                <Image 
                  className='mugshots'
                  style = {{
                    width: '90%'
                  }}
                  src={Nic} size='medium'
                />
              </Grid.Row>
              <Grid.Row style={{width: '67%'}}>
                <h1 className='qandaText' style={{fontSize: '25px'}}>Nicole - Maid of Honour</h1>
                <p className='qandaTextBody' style={{fontSize: '30px'}}>Nic and Billie worked together over the years in restaurants around Coventry, they quickly became each other&apos;s source of laughter, shoulder to cry on and most importantly, best friend. Nic is like a sister to Bill and they really are just two peas in a pod, despite having completely polar opposite asthetics (pastel pink doesn&apos;t make much of an appearance in Billie&apos;s wardrobe). Undeterred by the looming possibility of Billie stealing Bubble & Squeak (Nic&apos;s two guinea pigs), they have remained firm friends and Billie wouldn&apos;t have been able to do the day without her here.</p>
              </Grid.Row>

              <Grid.Row>
                <Image 
                  className='mugshots'
                  style = {{
                    width: '90%'
                  }}
                  src={Gregor} size='medium'
                />
              </Grid.Row>
              <Grid.Row style={{width: '67%'}}>
                <h1 className='qandaText' style={{fontSize: '25px'}}>Greg - Best Man</h1>
                <p className='qandaTextBody' style={{fontSize: '30px'}}>Greg and Henry met at university in 2017, they shared a flat and many drunken nights together out in Leicester. Greg and Henry have a love for fast cars, especially Henry&apos;s most prized possession, his Toyota Supra (AKA. the other woman in his life). Keep an ear out for his best man speech, which will no doubt be filled with embarrassing tales, lots of bromance and some alcohol-induced “I love you&apos;s”!</p>
              </Grid.Row>

              <Grid.Row>
                <Image 
                  className='mugshots'
                  style = {{
                    width: '90%'
                  }}
                  src={Tom} size='medium'
                />
              </Grid.Row>
              <Grid.Row style={{width: '67%'}}>
                <h1 className='qandaText' style={{fontSize: '25px'}}>Tom - Groomsman</h1>
                <p className='qandaTextBody' style={{fontSize: '30px'}}>Tom is Henry&apos;s childhood friend from Ross on Wye, and witness to many of the earlier episodes of his poor decision making. It is highly likely that the patience that allows Tom to be a secondary school teacher and his continued friendship with Henry may have something in common (but he hasn&apos;t given him detention yet!). A reliable source of history facts and an occasional appearence of the second best moustache around, this should hopefully be an occasion that tests his saintly patience less than occasions with Henry have in the past!</p>
              </Grid.Row>

              <Grid.Row>
                <Image 
                  className='mugshots'
                  style = {{
                    width: '90%'
                  }}
                  src={Tue} size='medium'
                />
              </Grid.Row>
              <Grid.Row style={{width: '67%'}}>
                <h1 className='qandaText' style={{fontSize: '25px'}}>Tue - Mother of the Bride</h1>
                <p className='qandaTextBody' style={{fontSize: '30px'}}>Tue is Billie&apos;s long suffering mother, who after 22 years is understandably looking forward to handing the reins over to Henry. As a food-motivated professional gardener, if you need to find her throughout the evening, she will almost certainly either be in the gardens inspecting the flowers or devouring canapés. She&apos;s done a wonderful job babysitting her four-legged, furry grandson Frank whenever we needed to have a wedding planning day and we can&apos;t thank her enough for being so helpful throughout all of this. Ever since Billie was a little girl, she&apos;d been talking to her mum about what she imagined her wedding day would eventually look like, and now she can&apos;t wait to have her by her side on the big day.</p>
              </Grid.Row>
            </Grid>
          </MobileView>
          
        </div>
        <Footer/>
      </div>
      );
  }
  
export default WeddingParty;