import React from 'react';
import '../css_components/App.css';
import "../css_components/QandA.css";
import "../css_components/TravelGuide.css"
import { Grid, Image } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import background3 from '../images/travel header.jpg';
import Footer from './Footer.js';
import { BrowserView, MobileView, CustomView, TabletView, isMobile, isTablet } from 'react-device-detect';
import backgroundMobile from '../images/travelMobile.jpg';
import backgroundTablet from '../images/travel header tablet.jpg'

function TravelGuide() {
    return (
      <div>
        <BrowserView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: undefined,
                  aspectRatio: 1600/793
                }}
                src={background3} size='medium' centered 
              />
            </div>
            <div className='text-on-image'>
              <h3 className='header1'>Travel and accomodation</h3>
            </div>
          </div>
        </BrowserView>
        <CustomView condition={isMobile && !isTablet}>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top:'87vw'}}>
              <h3 className='header1' style={{fontSize:'40px'}}>Travel and accomodation</h3>
            </div>
          </div>
        </CustomView>
        <TabletView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 4/5
                }}
                src={backgroundTablet} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top:'70vw'}}>
              <h3 className='header1' style={{fontSize:'40px'}}>Travel and accomodation</h3>
            </div>
          </div>
        </TabletView>
        
      <div>
          <BrowserView>
            <Grid class='qanda' divided='vertically'>
              <Grid.Row>
              <Grid.Column style={{width: '50%'}} class='travelColumn'>
                  <h1 className='travelText'>Aviator Hotel</h1>
                  <p className='travelTextBody'>12 minutes from Rivervale Barn</p>
                  <p className='travelTextBody'>Farnborough Road, Farnborough, Hampshire</p>
                  <p className='travelTextBody'>GU14 6EL</p>
                  <p className='travelTextBody'>01252555890</p>
                  <p className='travelTextBody'><Link class='extLink' to='https://www.aviatorhampshire.com/'><p className='venuePara'>www.aviatorbytag.com</p></Link></p>
                </Grid.Column>
                <Grid.Column style={{width: '50%'}} class='travelColumn'>
                  <h1 className='travelText'>Warbrook House Hotel</h1>
                  <p className='travelTextBody'>5 minutes from Rivervale Barn</p>
                  <p className='travelTextBody'>Eversley, near Hook, Hampshire</p>
                  <p className='travelTextBody'>RG27 0PL</p>
                  <p className='travelTextBody'>01189732174</p>
                  <p className='travelTextBody'><Link class='extLink' to='https://www.warbrookhouse.com/'><p className='venuePara'>www.warbrookhouse.com</p></Link></p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column style={{width: '50%'}} class='travelColumn'>
                  <h1 className='travelText'>The Casa Hotel</h1>
                  <p className='travelTextBody'>3 minutes from Rivervale Barn</p>
                  <p className='travelTextBody'>Handford Lane, Yateley, Hampshire</p>
                  <p className='travelTextBody'>GU46 6BT</p>
                  <p className='travelTextBody'>01252749144</p>
                  <p className='travelTextBody'><Link class='extLink' to='https://events.thecasahoteluk.com/rivervalebarn/'><p className='venuePara'>www.thecasahoteluk.com</p></Link></p>
                </Grid.Column>
                <Grid.Column style={{width: '50%'}} class='travelColumn'>
                  <h1 className='travelText'>Lismoyne Hotel</h1>
                  <p className='travelTextBody'>8 minutes from Rivervale Barn</p>
                  <p className='travelTextBody'>Church Road, Fleet, Hampshire</p>
                  <p className='travelTextBody'>GU51 4NE</p>
                  <p className='travelTextBody'>01252628555</p>
                  <p className='travelTextBody'><Link class='extLink' to='https://www.lismoynehotel.com/'><p className='venuePara'>www.lismoynehotel.com</p></Link></p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid class='qanda' divided='horizontally'>
            <Grid.Row>
                <Grid.Column class='travelColumn' style={{width: '33%'}}>
                  <h1 className='travelText' style={{fontSize: '25px'}}>Nearest Train Station: Sandhurt</h1>
                  <p className='travelTextBody'>4 minutes from Rivervale Barn</p>
                </Grid.Column>
                <Grid.Column class='travelColumn' style={{width: '33%'}}>
                  <h1 className='travelText' style={{marginLeft: '10%', fontSize: '25px'}}>Nearest Airport: London Heathrow</h1>
                  <p className='travelTextBody'style={{marginLeft: '10%'}}>30 minutes from Rivervale Barn</p>
                </Grid.Column>
                <Grid.Column class='travelColumn' style={{width: '33%'}}>
                  <h1 className='travelText' style={{marginLeft: '10%', fontSize: '25px'}}>Local Taxi Providers</h1>
                  <p className='travelTextBody'style={{marginLeft: '10%'}}>Yateley Taxis: 01252860662</p>
                  <p className='travelTextBody'style={{marginLeft: '10%'}}>Macs Cabs: 07919915912</p>
                  <p className='travelTextBody'style={{marginLeft: '10%'}}>Bab Taxis: 01252860662</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </BrowserView>
          <MobileView>
            <Grid class='qanda' divided='vertically'>
            <Grid.Row class='travelColumn'>
                <h1 className='travelText'>Aviator Hotel</h1>
                <p className='travelTextBody'>12 minutes from Rivervale Barn</p>
                <p className='travelTextBody'>Farnborough Road, Farnborough, Hampshire</p>
                <p className='travelTextBody'>GU14 6EL</p>
                <p className='travelTextBody'>01252555890</p>
                <p className='travelTextBody'><Link class='extLink' to='https://www.aviatorhampshire.com/'><p className='venuePara'>www.aviatorbytag.com</p></Link></p>
              </Grid.Row>
              <Grid.Row class='travelColumn'>
                <h1 className='travelText'>Warbrook House Hotel</h1>
                <p className='travelTextBody'>5 minutes from Rivervale Barn</p>
                <p className='travelTextBody'>Eversley, near Hook, Hampshire</p>
                <p className='travelTextBody'>RG27 0PL</p>
                <p className='travelTextBody'>01189732174</p>
                <p className='travelTextBody'><Link class='extLink' to='https://www.warbrookhouse.com/'><p className='venuePara'>www.warbrookhouse.com</p></Link></p>
              </Grid.Row>
              <Grid.Row class='travelColumn'>
                <h1 className='travelText'>The Casa Hotel</h1>
                <p className='travelTextBody'>3 minutes from Rivervale Barn</p>
                <p className='travelTextBody'>Handford Lane, Yateley, Hampshire</p>
                <p className='travelTextBody'>GU46 6BT</p>
                <p className='travelTextBody'>01252749144</p>
                <p className='travelTextBody'><Link class='extLink' to='https://events.thecasahoteluk.com/rivervalebarn/'><p className='venuePara'>www.thecasahoteluk.com</p></Link></p>
              </Grid.Row>
              <Grid.Row class='travelColumn'>
                <h1 className='travelText'>Lismoyne Hotel</h1>
                <p className='travelTextBody'>8 minutes from Rivervale Barn</p>
                <p className='travelTextBody'>Church Road, Fleet, Hampshire</p>
                <p className='travelTextBody'>GU51 4NE</p>
                <p className='travelTextBody'>01252628555</p>
                <p className='travelTextBody'><Link class='extLink' to='https://www.lismoynehotel.com/'><p className='venuePara'>www.lismoynehotel.com</p></Link></p>
              </Grid.Row>
            </Grid>
            <Grid class='qanda' divided='vertically'>
              <Grid.Row class='travelColumn' style={{width: '80%'}}>
                <h1 className='travelText'>Nearest Train Station:</h1>
                <h1 className='travelText' style={{marginTop: '2%'}}>Sandhurt</h1>
                <p className='travelTextBody' style={{width: '80%'}}>4 minutes from Rivervale Barn</p>
              </Grid.Row>
              <Grid.Row class='travelColumn' style={{width: '80%'}}>
                <h1 className='travelText'>Nearest Airport:</h1>
                <h1 className='travelText' style={{marginTop: '2%'}}>London Heathrow</h1>
                <p className='travelTextBody' style={{width: '80%'}}>30 minutes from Rivervale Barn</p>
              </Grid.Row>
              <Grid.Row class='travelColumn' style={{width: '80%'}}>
                <h1 className='travelText'>Local Taxi Providers:</h1>
                <p className='travelTextBody' style={{width: '80%'}}>Yateley Taxis: 01252860662</p>
                <p className='travelTextBody' style={{width: '80%'}}>Macs Cabs: 07919915912</p>
                <p className='travelTextBody' style={{width: '80%'}}>Bab Taxis: 01252860662</p>
              </Grid.Row>
            </Grid>
          </MobileView>

          
          <div className='mapsDiv'>
            <BrowserView>
              <Grid>
                <Grid.Row>
                  <Grid.Column class='mapColumnRight'>
                    <iframe className='mapBox' title='map' width="600" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm5YZF-CBdkgRVKrCJUocjyU&key=AIzaSyDnSsmuV7j5Ul0V7sIJBaC0hz3zMlWf-d0"></iframe>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </BrowserView>
            <MobileView>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column class='mapColumnRight'>
                    <iframe className='mapBox' title='map' width="600" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm5YZF-CBdkgRVKrCJUocjyU&key=AIzaSyDnSsmuV7j5Ul0V7sIJBaC0hz3zMlWf-d0"></iframe>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </MobileView>
          </div>
        </div>
      <Footer/>
    </div>
      );
  }
  
export default TravelGuide;