import '../css_components/RSVPForm.css'
import React from 'react';
import { BrowserView, CustomView, TabletView, isMobile, isTablet } from 'react-device-detect';
import PropTypes from 'prop-types';

function GuestNameBox(props) {
    if (props.guest === 'Guest') {
        return(
            <div>
                <BrowserView>
                    <label className='RSVPLabelBottom'>
                        Please enter full name of guest:
                        <input 
                            className="allergyInput"
                            name="songrequest"
                            type="text"
                            placeholder=""
                            style={{marginLeft: '10px'}}
                            onInput={e => props.oninputChange(e.target.value, "guestName", "guestName", props.index)}
                        />
                    </label>
                </BrowserView>
                <CustomView condition={isMobile && !isTablet}>
                    <label className='RSVPLabelBottom' style={{fontSize: '3.5vw'}}>
                        Please enter full name of guest:
                        <input 
                            className="allergyInput"
                            name="songrequest"
                            type="text"
                            placeholder=""
                            style={{marginLeft: '10px', height: '7vw', width: '54vw', fontSize: '2.8vw'}}
                            onInput={e => props.oninputChange(e.target.value, "guestName", "guestName", props.index)}
                        />
                    </label>
                </CustomView>
                <TabletView>
                    <label className='RSVPLabelBottom'  style={{fontSize: '3vw'}}>
                        Please enter full name of guest:
                        <input 
                            className="allergyInput"
                            name="songrequest"
                            type="text"
                            placeholder=""
                            style={{marginLeft: '10px', height: '5vw', width: '54vw'}}
                            onInput={e => props.oninputChange(e.target.value, "guestName", "guestName", props.index)}
                        />
                    </label>
                </TabletView>
            </div>
        );
    }
    else {
        return(<div></div>)
    }
}

GuestNameBox.propTypes = {
    guest: PropTypes.string.isRequired,
    oninputChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
  }

export default GuestNameBox;