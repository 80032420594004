import { Grid, Image, Container, Segment, Divider } from 'semantic-ui-react'
import logo from '../images/logo.png';
import '../css_components/Footer.css';
import { BrowserView, MobileView } from 'react-device-detect';
import React from 'react';

function Footer() {
    return (
      <div>
        <Segment inverted vertical style={{ width: '100%', margin: '0em 0em 0em', padding: '0em 0em' }}>
            <Container textAlign='center'>
              <BrowserView>
                <p className='creditsSub' style={{paddingTop: '3%'}}>Any queries or urgent enquiries, please contact us at billieaprilwhite@gmail.com</p>
              </BrowserView>
              <MobileView>
                <p className='creditsSub' style={{paddingTop: '10%'}}>Any queries or urgent enquiries, please contact us at billieaprilwhite@gmail.com</p>
              </MobileView>
              <BrowserView>
                <Divider inverted section />
              </BrowserView>
              <MobileView>
                <Divider inverted section style={{marginBottom: '2vw'}}/>
              </MobileView>
              <BrowserView>
                <Grid divided inverted stackable centered style={{paddingTop: '0', paddingBottom: '0'}}>
                  <Grid.Column className='desktopFooterColumns' style={{width: '50%'}}>
                    <Grid.Row style={{height: '4vw'}}>
                      <a className='credits' href="https://www.freepik.com/free-photo/magnificent-scenery-beach-with-trees-sea-during-sunset_10809909.htm#query=Beach%20sunset%20dark&position=23&from_view=search&track=ais">Image by wirestock on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '4vw'}}>
                      <a className='credits' href="https://www.freepik.com/free-photo/closeup-shot-luxurious-bouquet-orange-roses-white-flowers-black_12947540.htm#query=dark%20floral&position=32&from_view=keyword&track=ais">Image by wirestock on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '4vw'}}>
                      <a className='credits' href="https://www.freepik.com/free-photo/wedding-bridal-accessories-pink-purple-colors-ceremonial-champagne-glasses-wedding-bouquets-bride-bridesmaids_8316070.htm#query=Bridesmaids&position=36&from_view=search&track=sph">Image by freepic.diller on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '4vw'}}>
                      <a className='credits' href="https://www.freepik.com/free-photo/beautiful-bouquet-wild-flowers-hands-bride_4563898.htm#query=wedding&position=37&from_view=search&track=sph">Image by freepic.diller on Freepik</a>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column className='desktopFooterColumns' style={{width: '50%'}}>
                    <Grid.Row style={{height: '4vw'}}>
                      <a className='credits' href="https://www.freepik.com/free-photo/top-view-pink-flowers-black-water-with-copy-space_5200440.htm#page=3&query=petals%20in%20black%20water&position=5&from_view=search&track=ais">Image on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '4vw'}}>
                      <a className='credits' href="https://www.freepik.com/free-photo/top-view-pressed-flowers_40392142.htm#page=2&query=paper%20texture%20pressed%20flowers&position=4&from_view=search&track=ais">Image on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '4vw'}}>
                      <a className='credits' href="https://www.freepik.com/free-ai-image/grilled-steak-fillet-with-fresh-vegetable-salad-generated-by-ai_41481642.htm#query=fine%20dining%20food&position=4&from_view=search&track=ais">Image By vecstock</a>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </BrowserView>
              <MobileView>
                <Grid divided inverted stackable centered style={{paddingTop: '0', paddingBottom: '0'}}>
                  <Grid.Column className='mobileFooterColumns' style={{width: '50%'}}>
                    <Grid.Row style={{height: '10vw'}}>
                      <a className='credits' style={{fontSize: '10px', lineHeight: '15px'}} href="https://www.freepik.com/free-photo/magnificent-scenery-beach-with-trees-sea-during-sunset_10809909.htm#query=Beach%20sunset%20dark&position=23&from_view=search&track=ais">Image by wirestock on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '10vw'}}>
                      <a className='credits' style={{fontSize: '10px', lineHeight: '15px'}} href="https://www.freepik.com/free-photo/closeup-shot-luxurious-bouquet-orange-roses-white-flowers-black_12947540.htm#query=dark%20floral&position=32&from_view=keyword&track=ais">Image by wirestock on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '10vw'}}>
                      <a className='credits' style={{fontSize: '10px', lineHeight: '15px'}} href="https://www.freepik.com/free-photo/wedding-bridal-accessories-pink-purple-colors-ceremonial-champagne-glasses-wedding-bouquets-bride-bridesmaids_8316070.htm#query=Bridesmaids&position=36&from_view=search&track=sph">Image by freepic.diller on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '10vw'}}>
                      <a className='credits' style={{fontSize: '10px', lineHeight: '15px'}} href="https://www.freepik.com/free-photo/beautiful-bouquet-wild-flowers-hands-bride_4563898.htm#query=wedding&position=37&from_view=search&track=sph">Image by freepic.diller on Freepik</a>
                    </Grid.Row>
                  </Grid.Column>
                  <Grid.Column className='mobileFooterColumns' style={{width: '50%'}}>
                    <Grid.Row style={{height: '10vw'}}>
                      <a className='credits' style={{fontSize: '10px', lineHeight: '15px'}} href="https://www.freepik.com/free-photo/top-view-pink-flowers-black-water-with-copy-space_5200440.htm#page=3&query=petals%20in%20black%20water&position=5&from_view=search&track=ais">Image on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '10vw'}}>
                      <a className='credits' style={{fontSize: '10px', lineHeight: '15px'}} href="https://www.freepik.com/free-photo/top-view-pressed-flowers_40392142.htm#page=2&query=paper%20texture%20pressed%20flowers&position=4&from_view=search&track=ais">Image on Freepik</a>
                    </Grid.Row>
                    <Grid.Row style={{height: '10vw'}}>
                      <a className='credits' style={{fontSize: '10px', lineHeight: '15px'}} href="https://www.freepik.com/free-ai-image/grilled-steak-fillet-with-fresh-vegetable-salad-generated-by-ai_41481642.htm#query=fine%20dining%20food&position=4&from_view=search&track=ais">Image By vecstock</a>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </MobileView>
              <BrowserView>
                <Divider inverted section />
              </BrowserView>
              <MobileView>
                <Divider inverted section style={{marginBottom: '2vw', marginTop: '2vw'}}/>
              </MobileView>
              <BrowserView>
                <Image centered size='mini' src={logo} style={{width: '5vw', height: '5vw'}}/>
              </BrowserView>
              <MobileView>
                <Image centered size='mini' src={logo} style={{width: '20vw', height: '20vw'}}/>
              </MobileView>
            </Container>
          </Segment>
      </div>
    );
  }
  
  export default Footer;