import React, { useEffect } from 'react';
import '../css_components/App.css'
import '../css_components/Common.css'
import '../css_components/Homepage.css'
import guests_info from '../guests.json';
import background1 from '../images/background1.jpg';
import background1Mobile from '../images/background1Mobile.jpg';
import background2 from '../images/rsvpBackground2.jpg'
import background2Mobile from '../images/rsvpBackground2Mobile.jpg'
import { Grid, Image } from 'semantic-ui-react'
import Select from "react-select";
import Modal from 'react-modal';
import Footer from './Footer.js';
import bouquet4 from '../images/bouquet9.png';
import bouquet4Desktop from '../images/bouquet9Desktop.png';
import { BrowserView, CustomView, isTablet, isMobile, TabletView } from 'react-device-detect';
import AllergiesCheckbox from './AllergiesCheckbox';
import GuestNameBox from './GuestNameBox';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 0,
    background: "translucent",
    border: "none"
  },
};

function Homepage() {
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState([]);
    const [responseValue, setResponseValue] = React.useState([]);
    const [rsvpAttendingValue, setRsvpAttendingValue] = React.useState([false, false, false, false]);
    const [rsvpNotAttendingValue, setRsvpNotAttendingValue] = React.useState([false, false, false, false]);
    const [menuVegeValue, setMenuVegeValue] = React.useState([false, false, false, false]);
    const [menuVegValue, setMenuVegValue] = React.useState([false, false, false, false]);
    const [menuStdValue, setMenuStdValue] = React.useState([false, false, false, false]);

    useEffect(() => {
      if (modalIsOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
    }, [modalIsOpen]);

    function oninputChange(value, input, inputType, index) {
        if (inputType === "rsvpAttending") {
          var tempVal1 = [...rsvpNotAttendingValue];
          tempVal1[index] = false;
          setRsvpNotAttendingValue(tempVal1);
          var tempVal2 = [...rsvpAttendingValue];
          tempVal2[index] = true;
          setRsvpAttendingValue(tempVal2);
        }
        if (inputType === "rsvpNotAttending") {
          var tempVal12 = [...rsvpAttendingValue];
          tempVal12[index] = false;
          setRsvpAttendingValue(tempVal12);
          var tempVal13 = [...rsvpNotAttendingValue];
          tempVal13[index] = true;
          setRsvpNotAttendingValue(tempVal13);
        }
        if (inputType === "menuStd") {
          var tempVal3 = [...menuVegValue];
          tempVal3[index] = false;
          setMenuVegValue(tempVal3);
          var tempVal4 = [...menuVegeValue];
          tempVal4[index] = false;
          setMenuVegeValue(tempVal4);
          var tempVal5 = [...menuStdValue];
          tempVal5[index] = true;
          setMenuStdValue(tempVal5);
        }
        if (inputType === "menuVege") {
          var tempVal6 = [...menuStdValue];
          tempVal6[index] = false;
          setMenuStdValue(tempVal6);
          var tempVal7 = [...menuVegValue];
          tempVal7[index] = false;
          setMenuVegValue(tempVal7);
          var tempVal8 = [...menuVegeValue];
          tempVal8[index] = true;
          setMenuVegeValue(tempVal8);
        }
        if (inputType === "menuVeg") {
          var tempVal9 = [...menuStdValue];
          tempVal9[index] = false;
          setMenuStdValue(tempVal9);
          var tempVal10 = [...menuVegeValue];
          tempVal10[index] = false;
          setMenuVegeValue(tempVal10);
          var tempVal11 = [...menuVegValue];
          tempVal11[index] = true;
          setMenuVegValue(tempVal11);
        }
        var temp = responseValue;
        temp["guestResponses"][index][input] = value;
        setResponseValue(temp);
    }

    async function onRSVPSubmit() {
      closeModal();

      await fetch('https://7ot5poa9b1.execute-api.eu-west-1.amazonaws.com/prod/updateguests', {
        method: 'POST',
        body: JSON.stringify(responseValue),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
        })
        .catch((err) => {
          console.log(err.message);
        });
    }

    function openModal() {
      setIsOpen(true);
      setMenuStdValue([false, false, false, false]);
      setMenuVegValue([false, false, false, false]);
      setMenuVegeValue([false, false, false, false]);
      setRsvpNotAttendingValue([false, false, false, false]);
      setRsvpAttendingValue([false, false, false, false]);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#000000';
    }
  
    function closeModal() {
      setIsOpen(false);
    }

    const updateValue = (e) => {
      setSelectedValue(e.guests);
      setResponseValue(e);
    };

    const getEnterStyle = () => ({width:(window?.navigator?.platform === 'iPhone' ? '50vw' : '55vw'), marginTop: '9%', height:'6vw', fontSize: (isTablet ? '3vw' : '4vw'), lineHeight: (isTablet ? '5vw' : '7vw')})

    return (
        <div>
          <div>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="RSVP Modal"
          >
            <h2 className='header1' ref={(_subtitle) => (subtitle = _subtitle)} style={{display:'none'}}>Please RSVP for all those listed below:</h2>
            <div>
              <BrowserView>
                  <div className='scrollBox'>
                      {selectedValue.map((guest, index) => (
                          <div key={index} className='borderDiv'>
                              <form className='RSVPForm'>
                                  <label className='RSVPLabelTop' style={{borderColor: 'black', borderRadius: '0', borderWidth: '3px'}}>
                                      <h3 className='RSVPHeader1'>{guest}</h3>
                                  </label>
                                  <GuestNameBox guest={guest} oninputChange={oninputChange} index={index}/>
                                  <label className='RSVPLabel' style={{width: '96%'}}>
                                      <h3 className='RSVPHeader2'>Please RSVP by the 30th of July 2024</h3>
                                  </label>
                                  <label className='RSVPLabel' style={{marginLeft: '17%'}}>
                                      <input
                                          className="rsvpInput1"
                                          type="checkbox"
                                          name="RSVP"
                                          onClick={() => oninputChange("true", "rsvp", "rsvpAttending", index)}
                                          checked={rsvpAttendingValue[index]}
                                      />
                                      Attending
                                  </label>
                                  <label className='RSVPLabel'>
                                      <input
                                          className="rsvpInput"
                                          type="checkbox"
                                          name="RSVP"
                                          onClick={() => oninputChange("false", "rsvp", "rsvpNotAttending", index)}
                                          checked={rsvpNotAttendingValue[index]}
                                      />
                                      Unable to attend
                                  </label>
                                  <label className='RSVPLabel' style={{width: '96%'}}>
                                      <h3 className='RSVPHeader2'>Menu choice:</h3>
                                  </label>
                                  <label className='RSVPLabel' style={{width: '20%', marginLeft: '18%'}}>
                                      <input
                                          className="rsvpInput1"
                                          type="checkbox"
                                          name="RSVP"
                                          onClick={() => oninputChange("standard", "menu", "menuStd", index)}
                                          checked={menuStdValue[index]}
                                      />
                                      Standard
                                  </label>
                                  <label className='RSVPLabel' style={{width: '20%'}}>
                                      <input
                                          className="rsvpInput1"
                                          type="checkbox"
                                          name="RSVP"
                                          onClick={() => oninputChange("vegetarian", "menu", "menuVege", index)}
                                          checked={menuVegeValue[index]}
                                      />
                                      Vegetarian
                                  </label>
                                  <label className='RSVPLabel' style={{width: '20%'}}>
                                      <input
                                          className="rsvpInput"
                                          type="checkbox"
                                          name="RSVP"
                                          onClick={() => oninputChange("vegan", "menu", "menuVeg", index)}
                                          checked={menuVegValue[index]}
                                      />
                                      Vegan
                                  </label>
                                  <AllergiesCheckbox oninputChange={oninputChange} index={index}/>
                                  <label className='RSVPLabel' style={{width: '70%', marginLeft: '18%', marginBottom: '3%'}}>
                                      Song Request:
                                      <input className="allergyInput" name="songrequest" type="text" placeholder="" style={{marginLeft: '10px'}} onInput={e => oninputChange(e.target.value, "songReq", "songReq", index)}/>
                                  </label>
                              </form>
                          </div>
                      ))}
                  </div>
              </BrowserView>
              <CustomView condition={isMobile && !isTablet}>
                  <div className='scrollBox' style={{maxHeight: '165vw'}}>
                      {selectedValue.map((guest, index) => (
                          <div key={index} className='borderDiv'>
                              <form className='RSVPForm' style={{width: '90%'}}>
                                  <label className='RSVPLabelTop' style={{borderColor: 'black', borderRadius: '0', borderWidth: '3px'}}>
                                      <h3 className='RSVPHeader1' style={{fontSize: '5vw'}}>{guest}</h3>
                                  </label>
                                  <div style={{justifyContent: 'left'}}>
                                    <GuestNameBox guest={guest} oninputChange={oninputChange} index={index}/>
                                    <label className='RSVPLabel' style={{width: '96%'}}>
                                        <h3 className='RSVPHeader2' style={{fontSize: '3.5vw'}}>Please RSVP by the 30th of July 2024</h3>
                                    </label>
                                    <label className='RSVPLabel' style={{textAlign: 'left', marginLeft: '16%', width: '80%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput1"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("true", "rsvp", "rsvpAttending", index)}
                                            checked={rsvpAttendingValue[index]}
                                        />
                                        Attending
                                    </label>
                                    <label className='RSVPLabel'  style={{textAlign: 'left', marginLeft: '16%', width: '80%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("false", "rsvp", "rsvpNotAttending", index)}
                                            checked={rsvpNotAttendingValue[index]}
                                        />
                                        Unable to attend
                                    </label>
                                    <label className='RSVPLabel' style={{width: '96%'}}>
                                        <h3 className='RSVPHeader2' style={{fontSize: '3.5vw'}}>Menu choice:</h3>
                                    </label>
                                    <label className='RSVPLabel' style={{textAlign: 'left', width: '80%', marginLeft: '16%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput1"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("standard", "menu", "menuStd", index)}
                                            checked={menuStdValue[index]}
                                        />
                                        Standard
                                    </label>
                                    <label className='RSVPLabel' style={{textAlign: 'left', width: '80%', marginLeft: '16%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput1"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("vegetarian", "menu", "menuVege", index)}
                                            checked={menuVegeValue[index]}
                                        />
                                        Vegetarian
                                    </label>
                                    <label className='RSVPLabel' style={{textAlign: 'left', width: '80%', marginLeft: '16%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("vegan", "menu", "menuVeg", index)}
                                            checked={menuVegValue[index]}
                                        />
                                        Vegan
                                    </label>
                                    <AllergiesCheckbox oninputChange={oninputChange} index={index}/>
                                    <label className='RSVPLabel' style={{textAlign: 'center', width: '100%', marginLeft: '0', marginBottom: '3%', fontSize: '3.5vw'}}>
                                        Song Request:
                                        <input className="allergyInput" name="songrequest" type="text" placeholder="" style={{width: '55%', height: '6vw', marginLeft: '10px', fontSize: '2.8vw'}} onInput={e => oninputChange(e.target.value, "songReq", "songReq", index)}/>
                                    </label>
                                  </div>
                              </form>
                          </div>
                      ))}
                  </div>
              </CustomView>
              <TabletView>
                  <div className='scrollBox' style={{maxHeight: '127vw'}}>
                      {selectedValue.map((guest, index) => (
                          <div key={index} className='borderDiv'>
                              <form className='RSVPForm' style={{width: '90%'}}>
                                  <label className='RSVPLabelTop' style={{borderColor: 'black', borderRadius: '0', borderWidth: '3px'}}>
                                      <h3 className='RSVPHeader1' style={{fontSize: '5vw'}}>{guest}</h3>
                                  </label>
                                  <div style={{justifyContent: 'left'}}>
                                    <GuestNameBox guest={guest} oninputChange={oninputChange} index={index}/>
                                    <label className='RSVPLabel' style={{width: '96%'}}>
                                        <h3 className='RSVPHeader2' style={{fontSize: '3.5vw'}}>Please RSVP by the 30th of July 2024</h3>
                                    </label>
                                    <label className='RSVPLabel' style={{textAlign: 'left', marginLeft: '16%', width: '80%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput1"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("true", "rsvp", "rsvpAttending", index)}
                                            checked={rsvpAttendingValue[index]}
                                        />
                                        Attending
                                    </label>
                                    <label className='RSVPLabel'  style={{textAlign: 'left', marginLeft: '16%', width: '80%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("false", "rsvp", "rsvpNotAttending", index)}
                                            checked={rsvpNotAttendingValue[index]}
                                        />
                                        Unable to attend
                                    </label>
                                    <label className='RSVPLabel' style={{width: '96%'}}>
                                        <h3 className='RSVPHeader2' style={{fontSize: '3.5vw'}}>Menu choice:</h3>
                                    </label>
                                    <label className='RSVPLabel' style={{textAlign: 'left', width: '80%', marginLeft: '16%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput1"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("standard", "menu", "menuStd", index)}
                                            checked={menuStdValue[index]}
                                        />
                                        Standard
                                    </label>
                                    <label className='RSVPLabel' style={{textAlign: 'left', width: '80%', marginLeft: '16%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput1"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("vegetarian", "menu", "menuVege", index)}
                                            checked={menuVegeValue[index]}
                                        />
                                        Vegetarian
                                    </label>
                                    <label className='RSVPLabel' style={{textAlign: 'left', width: '80%', marginLeft: '16%', fontSize: '3vw', marginBottom: '2vw'}}>
                                        <input
                                            className="rsvpInput"
                                            type="checkbox"
                                            name="RSVP"
                                            onClick={() => oninputChange("vegan", "menu", "menuVeg", index)}
                                            checked={menuVegValue[index]}
                                        />
                                        Vegan
                                    </label>
                                    <AllergiesCheckbox oninputChange={oninputChange} index={index}/>
                                    <label className='RSVPLabel' style={{textAlign: 'center', width: '100%', marginLeft: '0', marginBottom: '3%', fontSize: '3vw'}}>
                                        Song Request:
                                        <input className="allergyInput" name="songrequest" type="text" placeholder="" style={{width: '55%', height: '5vw', marginLeft: '10px'}} onInput={e => oninputChange(e.target.value, "songReq", "songReq", index)}/>
                                    </label>
                                  </div>
                              </form>
                          </div>
                      ))}
                  </div>
              </TabletView>
          </div>
            <BrowserView>
              <div style={{textAlign: 'center', marginTop: '1vw'}}>
                <button className='enterButton1' style={{marginRight: '1.5%'}} onClick={() => onRSVPSubmit()}>Submit</button>
                <button className='enterButton1' onClick={() => closeModal()} style={{marginLeft: '1.5%'}}>Close</button>
              </div>
            </BrowserView>
            <CustomView condition={isMobile && !isTablet}>
              <div style={{textAlign: 'center', marginTop: '1vw'}}>
                <button className='enterButton1' style={{marginRight: '1.5%', width: '17vw', height: '7vw', fontSize: '12px'}} onClick={() => onRSVPSubmit()}>Submit</button>
                <button className='enterButton1' onClick={() => closeModal()} style={{marginLeft: '1.5%', width: '17vw', height: '7vw', fontSize: '12px'}}>Close</button>
              </div>
            </CustomView>
            <TabletView>
              <div style={{textAlign: 'center', marginTop: '1vw'}}>
                <button className='enterButton1' style={{marginRight: '1.5%', width: '17vw', height: '7vw', fontSize: '19px'}} onClick={() => onRSVPSubmit()}>Submit</button>
                <button className='enterButton1' onClick={() => closeModal()} style={{marginLeft: '1.5%', width: '17vw', height: '7vw', fontSize: '19px'}}>Close</button>
              </div>
            </TabletView>
          </Modal>
          <BrowserView>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: undefined,
                  aspectRatio: 6720/3329
                }}
                src={background1} size='medium' centered 
              />
            </div>
            <div className='text-on-image'>
              <h3 className='header5'>The Wedding of</h3>
              <h3 className='header1'>Billie & Henry</h3>
              <p className='header4'> Wednesday, 30th October, 2024</p>
              <p className='header2'>Rivervale Barn, Hampshire</p>
            </div>
          </BrowserView>
          <CustomView condition={isMobile && !isTablet}>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1750/3329
                }}
                src={background1Mobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top:'60vw'}}>
              <h3 className='header5'>The Wedding of</h3>
              <h3 className='header1'>Billie</h3>
              <h3 className='header1' style={{marginTop: '-5vw'}}>&</h3>
              <h3 className='header1' style={{marginTop: '-5vw'}}>Henry</h3>
              <p className='header4'> Wednesday, 30th October, 2024</p>
              <p className='header2'>Rivervale Barn, Hampshire</p>
            </div>
          </CustomView>
          <TabletView>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1750/3329
                }}
                src={background1Mobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top:'60vw'}}>
              <h3 className='header5'>The Wedding of</h3>
              <h3 className='header1'>Billie</h3>
              <h3 className='header1' style={{marginTop: '-3vw'}}>&</h3>
              <h3 className='header1' style={{marginTop: '-3vw'}}>Henry</h3>
              <p className='header4'> Wednesday, 30th October, 2024</p>
              <p className='header2'>Rivervale Barn, Hampshire</p>
            </div>
          </TabletView>
          </div>

          <div className='rsvpDiv'>
            <BrowserView>
              <div className='head-text'>
                <Image 
                  style={{
                    width: '100vw',
                    height: undefined,
                    aspectRatio: 7999/4066
                  }}
                  src={background2} size='medium' centered 
                />
              </div>
              <div className='rsvpDiv2' style={{left: '30%', minHeight: '390px'}}>
                <div className='rsvpBorder' style={{minHeight: '321px'}}>
                  <div className='text-on-rsvp'>
                    <h3 className='header1'>RSVP</h3>
                    <p className='header3' style={{width: '90%', marginLeft: '5%', marginRight: '5%'}}>Please RSVP using the form below as soon as you can be sure who will and won&apos;t be attending</p>
                    <div className='selectWidth'>
                      <Select
                        name="accounts"
                        onChange={(e) => updateValue(e)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          colors: {
                            ...theme.colors,
                            primary: 'neutral30',
                            primary25: 'Gainsboro',
                          },
                        })}
                        styles={{
                          placeholder: provided => ({
                            ...provided,
                            marginLeft: '2.25vw'
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling',
                          }),
                          control: provided => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling',
                            border: 0,
                            // This line disable the blue border
                            boxShadow: 'none'
                          }),
                          singleValue: provided => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling'
                          })
                        }}
                        options={guests_info.sort((a, b) => a.name.localeCompare(b.name))}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                      />
                      <button className='enterButton' style={{marginTop: '9%'}} onClick={() => openModal(true)}>Enter</button>
                    </div>
                  </div>
                </div>
              </div>
            </BrowserView>
            <CustomView condition={isMobile && !isTablet}>
              <div className='head-text'>
                <Image 
                  style={{
                    width: '100vw',
                    height: '100vh',
                    aspectRatio: 1/2
                  }}
                  src={background2Mobile} size='medium' centered 
                />
              </div>
              <div className='rsvpDiv2' style={{top: '129vh', width: '90vw', height: '67vw', left:'5vw'}}>
                <div className='rsvpBorder' style={{height:'61vw', width:'84vw'}}>
                  <div className='text-on-rsvp'>
                    <h3 className='header1' style={{fontSize:'7vw'}}>RSVP</h3>
                    <p className='header3' style={{width: '90%', marginLeft: '5%', marginRight: '5%', fontSize:'4vw'}}>Please RSVP using the form below as soon as you can be sure who will and won&apos;t be attending</p>
                    <div className='selectWidth'>
                      <Select
                        name="accounts"
                        onChange={(e) => updateValue(e)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          width: '55vw',
                          colors: {
                            ...theme.colors,
                            primary: 'neutral30',
                            primary25: 'Gainsboro',
                          },
                        })}
                        styles={{
                          placeholder: provided => ({
                            ...provided,
                            marginLeft: '2.25vw'
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling',
                          }),
                          control: provided => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling',
                            border: 0,
                            // This line disable the blue border
                            boxShadow: 'none'
                          }),
                          singleValue: provided => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling'
                          })
                        }}
                        options={guests_info.sort((a, b) => a.name.localeCompare(b.name))}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                      />
                      <button className='enterButton' style={getEnterStyle()} onClick={() => openModal(true)}>Enter</button>
                    </div>
                  </div>
                </div>
              </div>
            </CustomView>
            <TabletView>
            <div className='head-text'>
                <Image 
                  style={{
                    width: '100vw',
                    height: '100vh',
                    aspectRatio: 1/2
                  }}
                  src={background2Mobile} size='medium' centered 
                />
              </div>
              <div className='rsvpDiv2' style={{top: '129vh', width: '90vw', height: '53vw', left:'5vw'}}>
                <div className='rsvpBorder' style={{height:'47vw', width:'84vw'}}>
                  <div className='text-on-rsvp'>
                    <h3 className='header1' style={{fontSize:'7vw'}}>RSVP</h3>
                    <p className='header3' style={{width: '90%', marginLeft: '5%', marginRight: '5%', fontSize:'4vw'}}>Please RSVP using the form below as soon as you can be sure who will and won&apos;t be attending</p>
                    <div className='selectWidth'>
                      <Select
                        name="accounts"
                        onChange={(e) => updateValue(e)}
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 0,
                          width: '55vw',
                          colors: {
                            ...theme.colors,
                            primary: 'neutral30',
                            primary25: 'Gainsboro',
                          },
                        })}
                        styles={{
                          placeholder: provided => ({
                            ...provided,
                            marginLeft: '2.25vw'
                          }),
                          option: (provided) => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling',
                          }),
                          control: provided => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling',
                            border: 0,
                            // This line disable the blue border
                            boxShadow: 'none'
                          }),
                          singleValue: provided => ({
                            ...provided,
                            color: 'black',
                            fontFamily: 'Castoro Titling'
                          })
                        }}
                        options={guests_info.sort((a, b) => a.name.localeCompare(b.name))}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                      />
                      <button className='enterButton' style={getEnterStyle()} onClick={() => openModal(true)}>Enter</button>
                    </div>
                  </div>
                </div>
              </div>
            </TabletView>
          </div>

          <div className='mapsDiv'>
            <BrowserView>
              <Grid>
                <Grid.Row>
                  <Grid.Column class='mapColumnLeft'>
                  <div className='address'>
                    <p className='addressText'>Rivervale Barn</p>
                    <p className='addressText'>Mill Farm, Mill Ln,</p>
                    <p className='addressText'>Yateley, GU46 7SS</p>
                  </div>
                  <Image 
                    style={{
                      width: '20vw',
                      height: undefined,
                      aspectRatio: 1/1,
                      marginLeft: '19.5vw',
                      marginTop: '-6%'
                    }}
                    src={bouquet4Desktop} size='medium' centered 
                  />
                  </Grid.Column>
                  <Grid.Column class='mapColumnRight'>
                    <iframe className='mapBox' title='map' width="600" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm5YZF-CBdkgRVKrCJUocjyU&key=AIzaSyDnSsmuV7j5Ul0V7sIJBaC0hz3zMlWf-d0"></iframe>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </BrowserView>
            <CustomView condition={isMobile && !isTablet}>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column class='mapColumnLeft' style={{height: '48vw'}}>
                  <div className='address'>
                    <p className='addressTextMobile'>Rivervale Barn</p>
                    <p className='addressTextMobile'>Mill Farm, Mill Ln,</p>
                    <p className='addressTextMobile'>Yateley, GU46 7SS</p>
                  </div>
                  <Image 
                    style={{
                      width: '60vw',
                      height: undefined,
                      aspectRatio: 1/1,
                      marginLeft: '36vw',
                      marginTop: '-43%'
                    }}
                    src={bouquet4} size='medium' centered 
                  />
                  </Grid.Column>
                  <Grid.Column class='mapColumnRight'>
                    <iframe className='mapBox' title='map' width="600" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm5YZF-CBdkgRVKrCJUocjyU&key=AIzaSyDnSsmuV7j5Ul0V7sIJBaC0hz3zMlWf-d0"></iframe>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </CustomView>
            <TabletView>
            <Grid stackable>
                <Grid.Row>
                  <Grid.Column class='mapColumnLeft' style={{height: '48vw'}}>
                  <div className='address'>
                    <p className='addressTextMobile'>Rivervale Barn</p>
                    <p className='addressTextMobile'>Mill Farm, Mill Ln,</p>
                    <p className='addressTextMobile'>Yateley, GU46 7SS</p>
                  </div>
                  <Image 
                    style={{
                      width: '60vw',
                      height: undefined,
                      aspectRatio: 1/1,
                      marginLeft: '-1vw',
                      marginTop: '-2vw'
                    }}
                    src={bouquet4} size='medium' centered 
                  />
                  </Grid.Column>
                  <Grid.Column class='mapColumnRight'>
                    <iframe className='mapBox' title='map' width="600" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm5YZF-CBdkgRVKrCJUocjyU&key=AIzaSyDnSsmuV7j5Ul0V7sIJBaC0hz3zMlWf-d0"></iframe>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </TabletView>
          </div>

          <Footer/>
        </div>
      );
  }
  
export default Homepage;