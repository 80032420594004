import React from 'react';
import '../css_components/App.css';
import '../css_components/Common.css';
import '../css_components/Homepage.css';
import '../css_components/Venue.css';
import background1 from '../images/rivervale-barn-summer-wedding-venues.jpg';
import { Grid, Image} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import Footer from './Footer.js';
import bouquet4 from '../images/bouquet9.png';
import { BrowserView, CustomView, TabletView, isMobile, isTablet } from 'react-device-detect';
import backgroundMobile from '../images/venueMobile.jpg';
import backgroundTablet from '../images/venueTablet.jpg';
import bouquet4Desktop from '../images/bouquet9Desktop.png';

function Venue() {
    return (
      <div>
        <BrowserView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: undefined,
                  aspectRatio: 6720/3329
                }}
                src={background1} size='medium' centered 
              />
            </div>
            <div className='text-on-image'>
              <h3 className='header1'>The Venue</h3>
            </div>
          </div>
        </BrowserView>
        <CustomView condition={isMobile && !isTablet}>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top: '87vw'}}>
              <h3 className='header1'>The Venue</h3>
            </div>
          </div>
        </CustomView>
        <TabletView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundTablet} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top: '70vw'}}>
              <h3 className='header1'>The Venue</h3>
            </div>
          </div>
        </TabletView>
        

      <div className='venueBody'>
        <div className='venueBlurb'>
            <h1 className='venueHeader'>Rivervale Barn, Hampshire</h1>
            <p className='venuePara'>Nestled in the heart of the Hampshire countryside alongside the banks of the Blackwater River in Yateley, Rivervale was the perfect place for us to say “I Do”! Rivervale has two ceremony locations, the outdoor spinney surrounded by gardens and woodland and the cathedral-style ceremony barn. If the weather all goes according to plan, we will be tying the knot under the spinney. However, in the event of inclement weather, we will be moving the ceremony to be held within the barn and shall update you on your arrival. After the ceremony, we will be serving a selection of canapés and drinks from the reception room for you to enjoy in the courtyard or whilst you explore the gardens at Rivervale and get some stunning photos! Later in the evening, after toasting some champagne and doing a few speeches, we will then be holding a four course meal in Rivervale’s dining hall. Our standard, vegetarian and vegan set menus are all crafted from fresh, locally-sourced, seasonal ingredients for you to enjoy.</p>
            <Link class='extLink' to='https://rivervale-barn-weddings.co.uk/'><p className='venuePara'>Rivervale Barn&apos;s website</p></Link>
        </div>
      </div>

      <div className='mapsDiv'>
      <BrowserView>
              <Grid>
                <Grid.Row>
                  <Grid.Column class='mapColumnLeft'>
                  <div className='address'>
                    <p className='addressText'>Rivervale Barn</p>
                    <p className='addressText'>Mill Farm, Mill Ln,</p>
                    <p className='addressText'>Yateley, GU46 7SS</p>
                  </div>
                  <Image 
                    style={{
                      width: '20vw',
                      height: undefined,
                      aspectRatio: 1/1,
                      marginLeft: '19.5vw',
                      marginTop: '-6%'
                    }}
                    src={bouquet4Desktop} size='medium' centered 
                  />
                  </Grid.Column>
                  <Grid.Column class='mapColumnRight'>
                    <iframe className='mapBox' title='map' width="600" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm5YZF-CBdkgRVKrCJUocjyU&key=AIzaSyDnSsmuV7j5Ul0V7sIJBaC0hz3zMlWf-d0"></iframe>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </BrowserView>
            <CustomView condition={isMobile && !isTablet}>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column class='mapColumnLeft' style={{height: '48vw'}}>
                  <div className='address'>
                    <p className='addressTextMobile'>Rivervale Barn</p>
                    <p className='addressTextMobile'>Mill Farm, Mill Ln,</p>
                    <p className='addressTextMobile'>Yateley, GU46 7SS</p>
                  </div>
                  <Image 
                    style={{
                      width: '60vw',
                      height: undefined,
                      aspectRatio: 1/1,
                      marginLeft: '36vw',
                      marginTop: '-41%'
                    }}
                    src={bouquet4} size='medium' centered 
                  />
                  </Grid.Column>
                  <Grid.Column class='mapColumnRight'>
                    <iframe className='mapBox' title='map' width="600" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm5YZF-CBdkgRVKrCJUocjyU&key=AIzaSyDnSsmuV7j5Ul0V7sIJBaC0hz3zMlWf-d0"></iframe>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </CustomView>
            <TabletView>
            <Grid stackable>
                <Grid.Row>
                  <Grid.Column class='mapColumnLeft' style={{height: '48vw'}}>
                  <div className='address'>
                    <p className='addressTextMobile'>Rivervale Barn</p>
                    <p className='addressTextMobile'>Mill Farm, Mill Ln,</p>
                    <p className='addressTextMobile'>Yateley, GU46 7SS</p>
                  </div>
                  <Image 
                    style={{
                      width: '60vw',
                      height: undefined,
                      aspectRatio: 1/1,
                      marginLeft: '-1vw',
                      marginTop: '-2vw'
                    }}
                    src={bouquet4} size='medium' centered 
                  />
                  </Grid.Column>
                  <Grid.Column class='mapColumnRight'>
                    <iframe className='mapBox' title='map' width="600" height="450" loading="lazy" allowfullscreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJm5YZF-CBdkgRVKrCJUocjyU&key=AIzaSyDnSsmuV7j5Ul0V7sIJBaC0hz3zMlWf-d0"></iframe>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </TabletView>
      </div>
      <Footer/>
    </div>
      );
  }
  
export default Venue