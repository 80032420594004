import React from 'react';
import '../css_components/App.css';
import "../css_components/QandA.css";
import "../css_components/Venue.css"
import {Image} from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import background3 from '../images/magnificent-scenery-beach-with-trees-sea-during-sunset.jpg';
import Footer from './Footer.js';
import { BrowserView, CustomView, TabletView, isMobile, isTablet } from 'react-device-detect';
import backgroundMobile from '../images/honeymoonMobile.jpg';

function Honeymoon() {
    return (
      <div>
        <BrowserView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: undefined,
                  aspectRatio: 6720/3329
                }}
                src={background3} size='medium' centered 
              />
            </div>
            <div className='text-on-image'>
              <h3 className='header1'>The Honeymoon Fund</h3>
            </div>
          </div>
        </BrowserView>
        <CustomView condition={isMobile && !isTablet}>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top: '87vw'}}>
              <h3 className='header1'>The Honeymoon Fund</h3>
            </div>
          </div>
        </CustomView>
        <TabletView>
          <div>
            <div className='head-text'>
              <Image 
                style={{
                  width: '100vw',
                  height: '100vh',
                  aspectRatio: 1/2
                }}
                src={backgroundMobile} size='medium' centered 
              />
            </div>
            <div className='text-on-image' style={{top: '70vw'}}>
              <h3 className='header1'>The Honeymoon Fund</h3>
            </div>
          </div>
        </TabletView>
        

      <div className='venueBody'>
        <div className='venueBlurb'>
            <h1 className='venueHeader'>The Honeymoon</h1>
            <p className='venuePara'>As we prepare to embark on a new journey together, your presence at our wedding is the greatest gift we could ask for. However, if you would like to contribute to our special day, we have created a honeymoon fund as opposed to a traditional wedding registry. Your generosity will help us create unforgettable memories and start our lives together on an incredible adventure. Thank you for your love and support, we can’t wait to celebrate with you!</p>
            <Link class='extLink' to='https://www.paypal.com/donate/?hosted_button_id=Y6FRA9R4AQ2XJ'><p className='venuePara'>Donate</p></Link>
        </div>
      </div>
      <Footer/>
    </div>
    );
  }
  
export default Honeymoon;