import React, { useState } from 'react'
import '../css_components/RSVPForm.css'
import { BrowserView, CustomView, TabletView, isMobile, isTablet} from 'react-device-detect';
import PropTypes from 'prop-types';

function AllergiesCheckbox(props) {
    const [checked, setChecked] = useState(false);
    
    return(
        <div>
            <BrowserView>
                <label className='RSVPLabelBottom'>
                    <input
                        className="rsvpInput1"
                        type="checkbox"
                        name="RSVP"
                        onChange={() => setChecked(!checked)}
                        checked={checked}
                    />
                    Allergies & Intolerances
                </label>
                <div style={{textAlign: 'center', marginBottom: '1vw'}}>
                {
                    checked ? (
                        <input className="allergyInput" name="token" type="text" placeholder="" onInput={e => props.oninputChange(e.target.value, "dietary", "allergies", props.index)}/>
                            ) : (<div></div>)
                }
                </div>
            </BrowserView>
            <CustomView condition={isMobile && !isTablet}>
                <label className='RSVPLabelBottom' style={{textAlign: 'left', width: '80%', marginLeft: '16%', fontSize: '3vw', marginBottom: '2vw'}}>
                    <input
                        className="rsvpInput1"
                        type="checkbox"
                        name="RSVP"
                        onChange={() => setChecked(!checked)}
                        checked={checked}
                    />
                    Allergies & Intolerances
                </label>
                <div style={{textAlign: 'center', marginBottom: '1vw'}}>
                {
                    checked ? (
                        <input className="allergyInput" name="token" type="text" placeholder="" style={{width: '54vw', height: '7vw', fontSize: '2.8vw'}} onInput={e => props.oninputChange(e.target.value, "dietary", "allergies", props.index)}/>
                            ) : (<div></div>)
                }
                </div>
            </CustomView>
            <TabletView>
                <label className='RSVPLabelBottom' style={{textAlign: 'left', width: '80%', marginLeft: '16%', fontSize: '3vw', marginBottom: '2vw'}}>
                    <input
                        className="rsvpInput1"
                        type="checkbox"
                        name="RSVP"
                        onChange={() => setChecked(!checked)}
                        checked={checked}
                    />
                    Allergies & Intolerances
                </label>
                <div style={{textAlign: 'center', marginBottom: '1vw'}}>
                {
                    checked ? (
                        <input className="allergyInput" name="token" type="text" placeholder="" style={{width: '54vw', height: '5vw'}} onInput={e => props.oninputChange(e.target.value, "dietary", "allergies", props.index)}/>
                            ) : (<div></div>)
                }
                </div>
            </TabletView>
        </div>
    );
}

AllergiesCheckbox.propTypes = {
    oninputChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
  }

export default AllergiesCheckbox;